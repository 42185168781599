import React, { Fragment } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import { Container, Row, Col } from "reactstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SupplierPageTitle from "../components/Elements/SupplierPageTitle"
import SupplierPageBasicInfo from "../components/Elements/SupplierPageBasicInfo"
import logo from "../assets/images/logo.png"

const menus = [
  { name: "HOME", id: "/" },
  { name: "PRAIRIE POINTE", id: "/prairie-pointe" },
  { name: "HIGHLAND POINTE", id: "/highland-pointe" },
  { name: "SOUTH POINTE", id: "/south-pointe" },
  { name: "ROYALWOOD", id: "/royalwood" },
  { name: "ABOUT US", id: "/about-us" },
  { name: "CONTACT US", id: "/contact-us" },
]

export default ({
  pageContext,
  pageContext: {
    id,
    title,
    content,
    slug,
    gallery,
    supplierLogo,
    address,
    email,
    facebook,
    googlePlus,
    openingHours,
    telephone,
    twitter,
    website,
  },
}) => {
  return (
    <Fragment>
      <Header menus={menus} className="LadcoHeaderArea" logo={logo} />
      <main className={`main-${slug}`} style={{ overflow: "hidden" }}>
        <SupplierPageTitle title={title}></SupplierPageTitle>
        <Container className="supplier-page-content-wrapper my-5" fluid="xl">
          <Row>
            <Col md="9">
              <SupplierPageBasicInfo
                title={title}
                supplierLogo={supplierLogo}
                address={address}
                telephone={telephone}
                email={email}
                website={website}
              />
              <div className="divider"></div>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Col>

          </Row>
        </Container>
      </main>
      <Footer menus={menus} className="LadcoFooterArea" />
    </Fragment>
  )
}
